



















































































import {
  defineComponent,
  PropType,
  ref,
  toRef,
  computed
} from '@nuxtjs/composition-api';
import type { Product } from '@wemade-vsf/catalog/lib/runtime/product/types';
import { useProductGallery } from '@wemade-vsf/catalog/lib/runtime/product/composables/useProductGallery';
import { SfModal, SfButton } from '@storefront-ui/vue';

export default defineComponent({
  name: 'ProductGalleryModal',
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null
    },
    current: {
      type: Number,
      default: 1
    },
    enableZoom: {
      type: Boolean,
      default: true
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SfModal,
    SfButton,
    VideoFacade: () => import('components/theme/Content/Magento/VideoFacade.vue')
  },
  setup (props) {
    const product = toRef(props, 'product');
    const { productGallery, imageSizes } = useProductGallery(product);

    const activeTab = ref('images')
    const activeIndex = ref<number>(props.current - 1);

    const selectedPicture = ref(productGallery.value?.[activeIndex.value] || { alt: '', zoom: '', big: { url: ''}, desktop: '' });
    const videoItems = computed(() => productGallery.value
      ? productGallery.value.filter(p => p.video && p.video.video_url )
      : [])
    const imageItems = computed(() => productGallery.value
      ? productGallery.value.filter(p => !p.video || !p.video.video_url )
      : []
    );

    const currentItems = computed(() => activeTab.value === 'images' ? imageItems.value : videoItems.value);

    function selectImage(index: number) {
      if (currentItems.value[index]) {
        activeIndex.value = index;
        selectedPicture.value = currentItems.value[index];
      }
    }

    return {
      activeTab,
      imageItems,
      videoItems,
      currentItems,
      selectedPicture,
      selectImage,
      imageSizes,
      activeIndex
    }
  }
})
